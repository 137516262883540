import React, { Fragment } from 'react'

function Skills() {
    return (
        <Fragment>
		<section className="skills" id="skills">
			<span className="number">03</span>
			<div className="container__two">
				<div className="main-title">
                    <h3>My Awesome Skills</h3>
                    <h2>My Skills</h2>
                </div>



            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{justifyContent: "center"}}>
            <li className="nav-item">
                <a style={{padding:"2rem 5rem"}} className="nav-link d-lg-block active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Backend engineer</a>
            </li>
            <li className="nav-item">
                <a style={{padding:"2rem 5rem"}} className="nav-link d-lg-block" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Devops Engineer</a>
            </li>
            <li className="nav-item">
                <a style={{padding:"2rem 5rem"}} className="nav-link d-lg-block" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Other ...</a>
            </li>
            </ul>
            <div className="tab-content" id="pills-tabContent" style={{padding: "4em 1rem"}}>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Programming languages</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                                <td>Familliar</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>JavaScript</li>
                                                        <li>TypeScript</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Golang</li>
                                                        <li>Dart</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                                                
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Frameworks</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                                <td>Familliar</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Node js ( Express )</li>
                                                        <li>Node js ( Fastify )</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Golang</li>
                                                        <li></li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Databases</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                                <td>Familliar</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>PostgresQL</li>
                                                        <li>MongoDB</li>
                                                        <li>MySQL</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Sqlite</li>
                                                        <li>Cockroach</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>API development</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                                <td>Familliar</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Rest api</li>
                                                        <li>TCP</li>
                                                        <li>Websockets</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Soap</li>
                                                        <li>GraphQL</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Architectures</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                                <td>Familliar</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Microservice</li>
                                                        <li>Domain driven</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Architectural partens</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                                <td>Familliar</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Model-view-controller</li>
                                                        <li>Layered pattern</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Client-server pattern</li>
                                                        <li>Peer-to-peer pattern</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
            </div>
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Continuous intergration</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Jenkins</li>
                                                        <li>Gitlab CI</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                                                
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Containerization</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Docker</li>
                                                        <li>Docker Compose</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>System monitoring</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Sentry</li>
                                                        <li>Prometheus</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Open tracing</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Jaeger</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Platforms</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Digital ocean</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Other...</h4>
                                        <div className="progress"></div>
                                        
                                    </div>
                                </div>
                            </div>
            </div>
            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div className="col-lg-4 col-sm-6">
                    <div className="progress-container">
                        <h4>Other skills</h4>
                        <div className="progress"></div>
                        <table>
                            <tr>
                                <td>Fluent</td>
                            </tr>
                            <tbody>
                                <td>
                                    <ul>
                                        <li>React js</li>
                                        <li>TypeScript</li>
                                    </ul>
                                </td>
                            </tbody>
                        </table>
                                                
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                        <div className="post">
                            <a href="https://streamanimey.com" className="post-more">
                                <div className="post-img">
                                    <img src="https://streamanimey.com/animey.png" className="img-fluid" alt=""/>
                                </div>
                                <div className="post-content">
                                    <hr />
                                    <div className="post-title">
                                        <h2>Stream animey.</h2>
                                    </div>
                                    <hr />
                                    <div className="post-text">
                                        <p>Welcome to stream animey a platform to watch all your favourite animes with comfort and affordably</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
            </div>
            </div>


			</div>
		</section>            
        </Fragment>
    )
}

export default Skills
