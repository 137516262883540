import React, { Fragment } from 'react'
import {ReactComponent as Address} from '../../assets/icons/address.svg';
// import {ReactComponent as Projects} from '../../assets/icons/projects.svg';
// import {ReactComponent as Phone} from '../../assets/icons/phone.svg';
import {ReactComponent as Linkedin} from '../../assets/icons/linkedin.svg';
import {ReactComponent as Github} from '../../assets/icons/github.svg';
import {ReactComponent as Mail} from '../../assets/icons/mail.svg';
import {ReactComponent as Whatsapp} from '../../assets/icons/whatsapp.svg';
import {ReactComponent as Medium} from '../../assets/icons/medium.svg';
import {ReactComponent as Twitter} from '../../assets/icons/twitter.svg';
// import { Link } from "react-router-dom";

function Landing() {
    return (
        <Fragment>
        <section className="home" id="home">
			<div className="overlay">
			    <div className="video-bg">
                   <video src="video/video.mp4" loop muted autoPlay></video>
                </div>
				<div className="container-fluid">					
					<div className="intro display-table text-center">
						<div className="display-table-cell">
							<img className="img-fluid" src="logo192.png" alt=""/>
							<h1>Joshua Isaac</h1>
							<p>Software architect &amp; engineer</p>
						</div>
					</div>
				</div>

                
				<div>

                    <div className=" footer text-center" style={{height: "auto",position: "absolute",top: "0px",padding: "0px",display: "flex",alignContent: "space-between",width: "min-content"}}>
                        <ul className="footer-social-icons list-unstyled">
                            <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none"}} className="a_res" href="tel:+254770396785" target="_newTab"><Address width="30px" height="30px" fill="#c79864" className="contact__body-icon" /></a></li>
                            <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none"}} className="a_res" href="https://www.linkedin.com/in/joshua-isaac-a66a9296/" target="_newTab"><Linkedin width="30px" height="30px" fill="#c79864" className="contact__body-icon" /></a></li>
                            <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none"}} className="a_res" href="mailto:jsisaacdev@gmail.com" target="_newTab"><Mail width="30px" height="30px" fill="#c79864" className="contact__body-icon" /></a></li>
                            <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none"}} className="a_res" href="https://github.com/jayisaac0" target="_newTab"><Github width="30px" height="30px" fill="#c79864" className="contact__body-icon" /></a></li>
                            <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none"}} className="a_res" href="https://jsisaacdev.medium.com/" target="_newTab"><Medium width="30px" height="30px" fill="#c79864" className="contact__body-icon" /></a></li>
                            <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none"}} className="a_res" href="https://api.whatsapp.com/send?phone=+254770396785&text=Hi,%20thank%20you%20for%20contacting%20me.%20How%20can%20i%20be%20of%20help?" target="_newTab"><Whatsapp width="30px" height="30px" fill="#c79864" className="contact__body-icon" /></a></li>
                            <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none"}} className="a_res" href="https://twitter.com/jayisaac0" target="_newTab"><Twitter width="30px" height="30px" fill="#c79864" className="contact__body-icon" /></a></li>
                        </ul>
                    </div>
				</div>
			</div>
        </section>
                    
        </Fragment>
    )
}

export default Landing
