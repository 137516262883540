import React, { Fragment } from 'react'

function Services() {
    return (
        <Fragment>
        <section className="services" id="services">
			<span className="number">02</span>
            <div className="container__two">
                <div className="main-title">
                    <h3>What I Do</h3>
                    <h2>My Services</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="service">
                            <div className="service-icon">
                                <i className="flaticon-creativity"></i>
                            </div>
                            <h4>Software architect</h4>
                            <p>
                            Defining structured solutions that meets all of the technical and operational requirements, while optimizing common quality attributes such as performance, security, and manageability.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="service">
                            <div className="service-icon">
                                <i className="flaticon-analysis"></i>
                            </div>
                            <h4>Devops engineer</h4>
                            <p>
                            Overcome limitations of the traditional waterfall model by getting involves a lot of development process, testing and deployment technologies for developing automated CI/ CD pipelines.
                            </p>
                        </div>
                    </div>
					<div className="col-lg-4 col-sm-6">
                        <div className="service">
                            <div className="service-icon">
                                <i className="flaticon-ui"></i>
                            </div>
                            <h4>Software engineer</h4>
                            <p>
                            Experianced Applications software engineer focused on creating revolutionary programs that include web browsers, database programs that will better the local and international markets.
                            </p>
                        </div>
                    </div>
				
                </div>
            </div>
        </section>            
        </Fragment>
    )
}

export default Services
