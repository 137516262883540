import React, { Fragment } from "react";
import { MobileView, BrowserView } from 'react-device-detect';
import { useForm } from 'react-hook-form';

// import BrowserLayout from "./browserView/layouts/BrowserLayout";
// import MobileLayout from "./mobileView/layouts/MobileLayout";
// import ProfileImage from '../assets/backgrounds/profile.png';
// import Logo from '../assets/logo/logo.png';
import { Switch, Route, Link } from 'react-router-dom'

// import {ReactComponent as Home} from '../assets/icons/home.svg';
import {ReactComponent as Address} from '../assets/icons/address.svg';
// import {ReactComponent as Projects} from '../assets/icons/projects.svg';
// import {ReactComponent as Phone} from '../assets/icons/phone.svg';
import {ReactComponent as Linkedin} from '../assets/icons/linkedin.svg';
import {ReactComponent as Github} from '../assets/icons/github.svg';
import {ReactComponent as Mail} from '../assets/icons/mail.svg';
import {ReactComponent as Whatsapp} from '../assets/icons/whatsapp.svg';
import {ReactComponent as Medium} from '../assets/icons/medium.svg';
import {ReactComponent as Twitter} from '../assets/icons/twitter.svg';
import Landing from "./mobileView/Landing";
import SimpleBottomNavigation from "./mobileView/SimpleBottomNavigation";
import About from "./mobileView/About";
import Skills from "./mobileView/Skills";
import Services from "./mobileView/Services";
import Experience from "./mobileView/Experience";
import swal from 'sweetalert';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
init("user_JBgJFINkiCsEZvK8SiJVd");

function App() {
    type emailer = {
        name: string;
        email: string;
        subject: number;
        message: number;
      };

    // const { handleSubmit, register, errors } = useForm();
    const { handleSubmit, register, errors } = useForm<emailer>();

    const onSubmit = async (data: emailer) => {
        swal({
            title: "Send email?",
            text: "Are you sure that you want to send jsisaac an email?",
            icon: "warning",
            dangerMode: false,
          })
          .then(willDelete => {
            if (willDelete) {

                var templateParams = {
                    name: data.name,
                    from: data.email,
                    subject: data.subject,
                    message: data.message
                };
                 
                emailjs.send('service_udsbmhq','template_4tdpd3j', templateParams)
                    .then(function(response) {
                    //    console.log('SUCCESS!', response.status, response.text);
                       swal("SUCCESS!", "Your email has been sent!", "success");
                    }, function(err) {
                    //    console.log('FAILED...', err);
                       swal("FAILLED!", "Your email has not been sent!", "error");
                    });      

            }
          });

    }

  return (
    <Fragment>
      <BrowserView>

      {/* <div className="switch-button"><i className="fa fa-cog fa-spin"></i></div>
        <div className="switched-styles">
            <h4>Color Switcher</h4>
            <ul className="list-unstyled">
                <li data-color="yellow"></li>
                <li data-color="orange"></li>
                <li data-color="gold"></li>
                <li data-color="green"></li>
                <li data-color="red"></li>
                <li data-color="blue"></li>
                <li data-color="purble"></li>
                <li data-color="rose"></li>
            </ul>
            <div className="hide-button">&times;</div>
        </div> */}
    
		<nav className="navbar navbar-expand-lg" style={{ position: "fixed",background: "#131415"}}>
			<div className="container__two">
				{/* <a className="navbar-brand" href="#"><span>Y</span>azen</a> */}
				<button className="menu-toggle">
					<span className="bar"></span>
					<span className="bar"></span>
					<span className="bar"></span>
				</button>
				<div className="navbar-collapse">
					<ul className="navbar-nav mr-auto">
						<li className="nav-item">
							<a className="nav-link active" href="#home">Home</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#about">About</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#services">Services</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#resume">Resume</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#portfolio">Portfolio</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#blog">Blog</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#contact">Contact</a>
						</li>
					</ul>
					<a className="contact d-none d-lg-block" href="#contact">Contact Me</a>
				</div>
			</div>
		</nav>
        


        <section className="home" id="home">
			<div className="overlay">
			    <div className="video-bg">
                   <video src="video/video.mp4" loop muted autoPlay></video>
                </div>
				<div className="container-fluid">					
					<div className="intro display-table text-center">
						<div className="display-table-cell">
							<img className="img-fluid" src="logo192.png" alt=""/>
							<h1>Joshua Isaac</h1>
							<h3 style={{color:"white"}}>Software engineer &amp; architect</h3>
                            <h4 style={{color:"white"}}><br /> GOLANG AND NODE JS </h4>
						</div>
					</div>
				</div>

                
				<div className="down" style={{width:"90%"}}>

            <div className="container footer text-center">
                {/* <a className="logo" href="#"><span>Y</span>azen</a> */}
                <ul className="footer-social-icons list-unstyled">
                    <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none",marginRight: "1rem"}} className="a_res" href="tel:+254770396785" target="_newTab"><Address width="54px" height="54px" fill="#c79864" className="contact__body-icon" /></a></li>
                    <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none",marginRight: "1rem"}} className="a_res" href="https://www.linkedin.com/in/joshua-isaac-a66a9296/" target="_newTab"><Linkedin width="54px" height="54px" fill="#c79864" className="contact__body-icon" /></a></li>
                    <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none",marginRight: "1rem"}} className="a_res" href="mailto:jsisaacdev@gmail.com" target="_newTab"><Mail width="54px" height="54px" fill="#c79864" className="contact__body-icon" /></a></li>
                    <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none",marginRight: "1rem"}} className="a_res" href="https://github.com/jayisaac0" target="_newTab"><Github width="54px" height="54px" fill="#c79864" className="contact__body-icon" /></a></li>
                    <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none",marginRight: "1rem"}} className="a_res" href="https://jsisaacdev.medium.com/" target="_newTab"><Medium width="54px" height="54px" fill="#c79864" className="contact__body-icon" /></a></li>
                    <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none",marginRight: "1rem"}} className="a_res" href="https://api.whatsapp.com/send?phone=+254770396785&text=Hi,%20thank%20you%20for%20contacting%20me.%20How%20can%20i%20be%20of%20help?" target="_newTab"><Whatsapp width="54px" height="54px" fill="#c79864" className="contact__body-icon" /></a></li>
                    <li style={{borderRadius: "50%",padding: "10px"}}><a style={{border: "none",marginRight: "1rem"}} className="a_res" href="https://twitter.com/jayisaac0" target="_newTab"><Twitter width="54px" height="54px" fill="#c79864" className="contact__body-icon" /></a></li>
                </ul>
            </div>
				</div>
			</div>
        </section>
        
        
        <section className="about-me" id="about">
			<span className="number">01</span>
            <div className="container__two">
                <div className="row">
                    <div className="col-xl-5 col-lg-6">
                        <div className="about-image">
                            <img className="img-fluid" src="https://image.freepik.com/free-vector/colorful-illustration-programmer-working_23-2148281409.jpg" alt=""/>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                        <div className="about-info">
                            <div className="main-title">
                                <h3>Discover</h3>
                                <h2>About Me</h2>
                            </div>
                            <p>
                            I am...<br />
                            I am an experienced back-end software engineer currently in working at Tospay adept in bringing forth expertise in design, installation, testing, and maintenance of software systems for over 3 years, Equipped with a promising skill set. Proficient in various platforms and languages. Experienced with the latest development tools and procedures.
                            </p>
                            <p>
                            My strongest skills and how I learned them...<br />
                            I am skilled with creating back-end systems utilizing micro-services and web-sockets for real-time services. I have a background in mathematics and computer science. I later broadened my skills by using Maximilian and Mosh.
                            </p>
                            <p>
                            What I am looking for...<br />
                            I am looking for a back-end node js software engineer position. These areas often draw upon the latest technologies in mathematics and Computer Science, and I know it would not only be enjoyable to work in, but they would allow me to grow and share my skills as a software engineer and try new stack by applying acquired knowledge as an engineer.
                            </p>
                            <div className="personal-info">
								<div className="row">
									<div className="col-sm-6">
										<p><span>Name: </span>Joshua Isaac</p>
									</div>
									<div className="col-sm-6">
										<p><span>Profession: </span>Software  engineer</p>
									</div>
									<div className="col-sm-6">
										<p><span>Phone: </span>+254 770 396 785</p>
									</div>
									<div className="col-sm-6">
										<p><span>Address: </span>Nairobi, KENYA</p>
									</div>
									{/* <div className="col-sm-6">
										<p><span>Experience: </span>4 Years</p>
									</div>
									<div className="col-sm-6">
										<p><span>Freelance: </span>Available</p>
									</div> */}
								</div>
							</div>
                            {/* <a href="#" className="main-btn">Download CV</a> */}
                        </div>
                    </div>
                </div> 
            </div>
        </section>
        


        <section className="services" id="services">
			<span className="number">02</span>
            <div className="container__two">
                <div className="main-title">
                    <h3>What I Do</h3>
                    <h2>My Services</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="service">
                            <div className="service-icon">
                                <i className="flaticon-creativity"></i>
                            </div>
                            <h4>Software architect</h4>
                            <p>
                            Defining structured solutions that meets all of the technical and operational requirements, while optimizing common quality attributes such as performance, security, and manageability.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="service">
                            <div className="service-icon">
                                <i className="flaticon-analysis"></i>
                            </div>
                            <h4>Devops engineer</h4>
                            <p>
                            Overcome limitations of the traditional waterfall model by getting involves a lot of development process, testing and deployment technologies for developing automated CI/ CD pipelines.
                            </p>
                        </div>
                    </div>
					<div className="col-lg-4 col-sm-6">
                        <div className="service">
                            <div className="service-icon">
                                <i className="flaticon-ui"></i>
                            </div>
                            <h4>Software engineer</h4>
                            <p>
                            Experianced Applications software engineer focused on creating revolutionary programs that include web browsers, database programs that will better the local and international markets.
                            </p>
                        </div>
                    </div>
				
                </div>
            </div>
        </section>


		<section className="skills" id="skills">
			<span className="number">03</span>
			<div className="container__two">
				<div className="main-title">
                    <h3>My Awesome Skills</h3>
                    <h2>My Skills</h2>
                </div>



            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{justifyContent: "center"}}>
            <li className="nav-item">
                <a className="nav-link d-lg-block active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Backend engineer</a>
            </li>
            <li className="nav-item">
                <a className="nav-link d-lg-block" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Devops Engineer</a>
            </li>
            <li className="nav-item">
                <a className="nav-link d-lg-block" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Other ...</a>
            </li>
            </ul>
            <div className="tab-content" id="pills-tabContent" style={{padding: "4em 1rem"}}>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Programming languages</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                                <td>Familliar</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>JavaScript</li>
                                                        <li>TypeScript</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Golang</li>
                                                        <li>Dart</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                                                
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Frameworks</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                                <td>Familliar</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Node js ( Express )</li>
                                                        <li>Node js ( Fastify )</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Golang</li>
                                                        <li></li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Databases</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                                <td>Familliar</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>PostgresQL</li>
                                                        <li>MongoDB</li>
                                                        <li>MySQL</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Sqlite</li>
                                                        <li>Cockroach</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>API development</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                                <td>Familliar</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Rest api</li>
                                                        <li>TCP</li>
                                                        <li>Websockets</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Soap</li>
                                                        <li>GraphQL</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Architectures</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                                <td>Familliar</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Microservice</li>
                                                        <li>Domain driven</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Architectural partens</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                                <td>Familliar</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Model-view-controller</li>
                                                        <li>Layered pattern</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Client-server pattern</li>
                                                        <li>Peer-to-peer pattern</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
            </div>
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Continuous intergration</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Jenkins</li>
                                                        <li>Gitlab CI</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                                                
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Containerization</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Docker</li>
                                                        <li>Docker Compose</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>System monitoring</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Sentry</li>
                                                        <li>Prometheus</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Open tracing</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Jaeger</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Platforms</h4>
                                        <div className="progress"></div>
                                        <table>
                                            <tr>
                                                <td>Fluent</td>
                                            </tr>
                                            <tbody>
                                                <td>
                                                    <ul>
                                                        <li>Digital ocean</li>
                                                    </ul>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="progress-container">
                                        <h4>Other...</h4>
                                        <div className="progress"></div>
                                        
                                    </div>
                                </div>
                            </div>
            </div>
            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div className="col-lg-4 col-sm-6">
                        <div className="progress-container">
                            <h4>Other skills</h4>
                            <div className="progress"></div>
                            <table>
                                <tr>
                                    <td>Fluent</td>
                                </tr>
                                <tbody>
                                    <td>
                                        <ul>
                                            <li>React js</li>
                                            <li>TypeScript</li>
                                        </ul>
                                    </td>
                                </tbody>
                            </table>                      
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="post">
                            <a href="https://streamanimey.com" className="post-more">
                                <div className="post-img">
                                    <img src="https://streamanimey.com/animey.png" className="img-fluid" alt=""/>
                                </div>
                                <div className="post-content">
                                    <hr />
                                    <div className="post-title">
                                        <h2>Stream animey.</h2>
                                    </div>
                                    <hr />
                                    <div className="post-text">
                                        <p>Welcome to stream animey a platform to watch all your favourite animes with comfort and affordably</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
            </div>
            </div>


			</div>
		</section>
		
        
        <section className="resume" id="resume">
			<span className="number">04</span>
            <div className="container__two">
                <div className="main-title">
                    <h3>Professional Experience &amp; </h3>
                    <h2>My Resume</h2>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="timeline">
                            <ul>
                                <li>
                                    <div className="timeline-content wow fadeInLeft" data-wow-duration=".8s" data-wow-offset="100">
                                        <span className="date">2020</span>
                                        <h4>Financial services</h4>
                                        <p>
                                            <b style={{color:"#c79864"}}>Clients:</b> Telkom kenya, Kenya ports authority, Tospay limited
                                        </p>
                                        I have mostly worked on financial integration and payment solutions with <hr />
                                        <p style={{display: "flex", textAlign: "left", flexDirection: "column", width: "100%",marginLeft:"5rem"}}>
                                        

+ Digital Wallet Service
<p>
    Money transfer API<br />
    Top up and Withdraw with bank, card and mobile money<br />
    Cross currency & boundary transfers<br />
</p>
+ Mobile Integration

<p>
    MPESA Integration :code
</p>
+ Card Integration

<p>
    Mastercard Payment Gateway :code
</p>
+ Other Financial Integrations

<p>
    PayPal Integration :code<br />
    Forex integration :code<br />
</p>

                                        </p>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                                    </div>
                                </li>
                                <li>
                                    <div className="timeline-content wow fadeInRight" data-wow-duration=".8s" data-wow-offset="100">
                                        <span className="date">2020</span>
                                        <h4>Notification services</h4>
                                        <p>
                                            <b style={{color:"#c79864"}}>Clients:</b> Telkom kenya, Kenya ports authority, Tospay limited, 
                                        </p>
                                        <p style={{display: "flex", textAlign: "left", flexDirection: "column", width: "100%",marginLeft:"5rem"}}>
+ SMS Integration
<p>
Infobip Integration :code<br />
SMS API Integration :code<br />
SMPP Integration :code<br />
</p>

+ Email Integration
<p>
SMTP Integration :code<br />
Sendgrid Integration :code<br />
</p>

+ Message Queue Integration
<p>
Redis Integration :code<br />
RabbitMQ Integration :code<br />
</p>
+ Other Notification Integration
<p>
Firebase Integration :code<br />
Socket IO Integration :code<br />                                      
</p>

                                        </p>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                                    </div>
                                </li>
                                <li>
                                    <div className="timeline-content wow fadeInLeft" data-wow-duration=".8s" data-wow-offset="100">
                                        <span className="date">2020</span>
                                        <h4>Gateway service</h4>
                                        <p>
                                            <b style={{color:"#c79864"}}>Clients:</b> Telkom kenya, Kenya ports authority, Tospay limited, Gandertechs
                                        </p>
                                        <p style={{display: "flex", textAlign: "left", flexDirection: "column", width: "100%",marginLeft:"5rem"}}>
                                        

+ Micro-services gateway Service
<p>
    Money transfer API<br />
    Top up and Withdraw with bank, card and mobile money<br />
    Cross currency & boundary transfers<br />
</p>
+ Services

<p>
    Notification service, License service, Wallet service,<br /> 
    Payment service, Configuration service,
    Mail service, Settlement service,<br /> Forex service, Switch service
</p>


                                        </p>                                        
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                                    </div>
                                </li>
                                <li>
                                    <div className="timeline-content wow fadeInRight" data-wow-duration=".8s" data-wow-offset="100">
                                        <span className="date">2020</span>
                                        <h4>File service</h4>
                                        <p>
                                            <b style={{color:"#c79864"}}>Clients:</b> Personal project, Tospay limited
                                        </p>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                                    </div>
                                </li>
                                <li>
                                    <div className="timeline-content wow fadeInLeft" data-wow-duration=".8s" data-wow-offset="100">
                                        <span className="date">2020</span>
                                        <h4>Tracer service library</h4>
                                        <p>
                                            <b style={{color:"#c79864"}}>Clients:</b> Personal project
                                        </p>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        
        <section className="facts">
			<div className="overlay"></div>
            <div className="container__two">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="fact-item">
                            <h4>Years experiance</h4>
							<span className="fact-number" data-from="0" data-to="720" data-speed="2500">4</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="fact-item">
                            <h4>Projects completed</h4>
							<span className="fact-number" data-from="0" data-to="700" data-speed="2500">30</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="fact-item">
                            <h4>Companies</h4>
							<span className="fact-number" data-from="0" data-to="1400" data-speed="2500">3</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="fact-item">
                            <h4>Coffee Cups</h4>
							<span className="fact-number" data-from="0" data-to="450" data-speed="2500">50</span><span>+</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        
        {/* <section className="portfolio" id="portfolio">
			<span className="number">05</span>
            <div className="container__two">
				<div className="main-title">
                    <h3>Recent Works</h3>
                    <h2>My Portfolio</h2>
                </div>
                <div className="filter-menu">
                    <ul id="control" className="list-filter list-unstyled">
                        <li className="active" data-filter="all">All</li>
                        <li data-filter="1">Front End</li>
                        <li data-filter="2">Back End</li>
                        <li data-filter="3">Graphic Design</li>
                    </ul>
                </div>
                <div className="portfolio-content">
                    <div id="filtr-container" className="row">
                        <div className="col-lg-4 col-md-6 filtr-item" data-category="1" data-sort="value">
                            <div className="item">
                                <img className="img-fluid" src="images/portfolio/item-1.jpg" alt=""/>
                                <div className="overlay">
                                    <h4 className="item-title">Project Title</h4>
                                    <a className="icon-img" href="images/portfolio/item-1.jpg"><i className="fa fa-search-plus"></i></a>
                                    <div className="item-category">
                                        <span>Front End</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 filtr-item" data-category="2" data-sort="value">
                            <div className="item">
                                <img className="img-fluid" src="images/portfolio/item-2.jpg" alt=""/>
                                <div className="overlay">
                                    <h4 className="item-title">Project Title</h4>
                                    <a className="icon-img" href="images/portfolio/item-2.jpg"><i className="fa fa-search-plus"></i></a>
                                    <div className="item-category">
                                        <span>Back End</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 filtr-item" data-category="3" data-sort="value">
                            <div className="item">
                                <img className="img-fluid" src="images/portfolio/item-3.jpg" alt=""/>
                                <div className="overlay">
                                    <h4 className="item-title">Project Title</h4>
                                    <a className="icon-img" href="images/portfolio/item-3.jpg"><i className="fa fa-search-plus"></i></a>
                                    <div className="item-category">
                                        <span>Graphic Design</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 filtr-item" data-category="1" data-sort="value">
                            <div className="item">
                                <img className="img-fluid" src="images/portfolio/item-4.jpg" alt=""/>
                                <div className="overlay">
                                    <h4 className="item-title">Project Title</h4>
                                    <a className="icon-img" href="images/portfolio/item-4.jpg"><i className="fa fa-search-plus"></i></a>
                                    <div className="item-category">
                                        <span>Front End</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 filtr-item" data-category="2" data-sort="value">
                            <div className="item">
                                <img className="img-fluid" src="images/portfolio/item-5.jpg" alt=""/>
                                <div className="overlay">
                                    <h4 className="item-title">Project Title</h4>
                                    <a className="icon-img" href="images/portfolio/item-5.jpg"><i className="fa fa-search-plus"></i></a>
                                    <div className="item-category">
                                        <span>Back End</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 filtr-item" data-category="3" data-sort="value">
                            <div className="item">
                                <img className="img-fluid" src="images/portfolio/item-6.jpg" alt=""/>
                                <div className="overlay">
                                    <h4 className="item-title">Project Title</h4>
                                    <a className="icon-img" href="images/portfolio/item-6.jpg"><i className="fa fa-search-plus"></i></a>
                                    <div className="item-category">
                                        <span>Graphic Design</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}


        <section className="testimonials" id="testimonials">
			<span className="number">06</span>
            <div className="container__two">
				<div className="main-title">
                    <h3>Latest organizations</h3>
                    <h2>Companies</h2>
                </div>
                <div className="row">
                    <div className="col-md-12 ">

                        <div className="testimonial-box">
							<i className="flaticon-right-quote"></i>
                            <div className="client-pic">
                                <img src="/tospay.png" alt="client"/>
                            </div>
                            <div className="client-details">
                                <h6>Tospay ltd</h6>
                                <span>Back-end engineer</span>
                            </div>                            
							<div className="description">
                                <p>
                                    <ul>
                                    <li>Develop, and execute services to meet company to company requirements and targets.</li>
                                    <li>Redesign and maintaining team code structure to ensure high performance, scalable and maintainable software.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div className="testimonial-box">
							<i className="flaticon-right-quote"></i>
                             <div className="client-pic">
                                <img src="https://media-exp1.licdn.com/dms/image/C4D0BAQGyTj5-w9TVgw/company-logo_200_200/0?e=1613001600&v=beta&t=ms3fy0HTfYANQEMRCFBTTj96gLIxoW5HhGcBHjRykY8" alt="client" />
                            </div>
                            <div className="client-details">
                                <h6>Gandertechs</h6>
                                <span>Back-end engineer</span>
                            </div>
							<div className="description">
                                <p>
                                    <ul>
                                    <li>Created the company gateway back-end service to be used across their software products.</li>
                                    <li>Contributed to the creating of the local payments service to help the company with transactions with the local mobile money provider.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        
  
        <section className="blog" id="blog">
			<span className="number">07</span>
            <div className="container__two">
				<div className="main-title">
                    <h3>Medium articles</h3>
                    <h2>My Blog</h2>
                </div>
                <div className="row">

                    <div className="col-md-4">
                        <div className="post">

                            <div className="post-img">
                                <img src="https://miro.medium.com/max/1000/1*lUttnIkKcFNxQooKufmBpA.png" className="img-fluid" alt=""/>
                            </div>

                            <div className="post-content">
                                <div className="post-title">
                                    <h4>Creating a gateway service with node js, typescript and fastify.</h4>
                                </div>
                                <div className="post-text">
                                    <p>In this article we are going to create a gateway service that will serve all future micro-services that we will learn to create...</p>
                                </div>
								<div className="post-date"><span>23</span>Oct</div>
                                <a href="https://jsisaacdev.medium.com/creating-a-gateway-service-with-node-js-typescript-and-fastify-54cb9c17b0e1" className="post-more">Read more</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="post">

                            <div className="post-img">
                                <img src="https://miro.medium.com/max/1000/1*iGMrhgo-wHXBgNmvXrSl1A.png" className="img-fluid" alt=""/>
                            </div>

                            <div className="post-content">
                                <div className="post-title">
                                    <h4>Building a socket api with node js, express, websocket and mongoDB</h4>
                                </div>
                                <div className="post-text">
                                    <p>This will be a detailed three part series to get you up and ready to build a cool real time back-end monolithic service...</p>
                                </div>
								<div className="post-date"><span>09</span>Oct</div>
                                <a href="https://jsisaacdev.medium.com/building-a-socket-api-with-node-js-express-websocket-and-mongodb-login-and-registration-e5e085fed3a0" className="post-more">Read more</a>
                            </div>
                        </div>
                    </div> 

                    <div className="col-md-4">
                        <div className="post">

                            <div className="post-img">
                                <img src="https://miro.medium.com/max/1000/1*qfl7z7Q6YHHDyyewCYLa4w.png" className="img-fluid" alt=""/>
                            </div>

                            <div className="post-content">
                                <div className="post-title">
                                    <h4>Creating a custom jaeger plugin to Monitor and troubleshoot complex distributed systems.</h4>
                                </div>
                                <div className="post-text">
                                    <p>As on-the-ground micro-service practitioners are quickly realizing, the majority of operational problems...</p>
                                </div>
								<div className="post-date"><span>21</span>Nov</div>
                                <a href="https://jsisaacdev.medium.com/creating-a-custom-jaeger-plugin-to-monitor-and-troubleshoot-complex-distributed-systems-be2af429ae86" className="post-more">Read more</a>
                            </div>
                        </div>
                    </div>        
                </div>
				<div className="my-btn">
					<a href="https://jsisaacdev.medium.com/" className="main-btn">All Posts</a>
				</div>
            </div>
        </section>
        


        <section className="contact" id="contact">
			<span className="number">08</span>
            <div className="container__two">
				<div className="main-title">
                    <h3>Get In Touch</h3>
                    <h2>Contact Me</h2>
                </div>
                <div className="row">
					<div className="col-md-4">
                        <div className="contact-boxes">
                            <div className="contact-box">
                                <span className="icon-box"><i className="flaticon-location"></i></span>
                                <h4 className="title-box">Location</h4>
                                <div className="content-box">
                                    <p>Nairobi, Kenya</p>
                                    {/* <p>Nairobi</p> */}
                                </div>
                            </div>
                            <div className="contact-box two">
                                <span className="icon-box"><i className="flaticon-email"></i></span>
                                <h4 className="title-box">Email</h4>
                                <div className="content-box">
                                    <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="20735550504f5254604558414d504c450e434f4d">jsisaacdev@gmail.com</a></p>
                                    {/* <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="0c5f797c7c637e784c69746d617c6069226f6361">[email&#160;protected]</a></p> */}
                                </div>
                            </div>
                            <div className="contact-box">
                                <span className="icon-box"><i className="flaticon-call"></i></span>
                                <h4 className="title-box">Phone</h4>
                                <div className="content-box">
                                    <p>+254 770 396 785</p>
                                    {/* <p>+1 212 658-2581</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <form className="contact-form" action="#" method="post" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <input ref={register({ required: "name is required" })} type="text" className="form-control" placeholder="Name" name="name"  />
                                {errors.name && ( <div style={{background: "#c79864",padding:" 5px",borderRadius:" 5px"}}> {errors.name.message} </div> )}                                
                            </div>
                            <div className="form-group">
                                <input ref={register({ required: "email is required" })} type="email" className="form-control" placeholder="Email" name="email"  />
                                {errors.email && ( <div style={{background: "#c79864",padding:" 5px",borderRadius:" 5px"}}> {errors.email.message} </div> )}                                
                            </div>
                            <div className="form-group">
                                <input ref={register({ required: "subject is required" })} type="text" className="form-control" placeholder="Subject" name="subject"  />
                                {errors.subject && ( <div style={{background: "#c79864",padding:" 5px",borderRadius:" 5px"}}> {errors.subject.message} </div> )}                                
                            </div>
                            <div className="form-group">
                                <textarea ref={register({ required: "message is required" })} className="form-control" placeholder="Your Message" name="message" ></textarea>
                                {errors.message && ( <div style={{background: "#c79864",padding:" 5px",borderRadius:" 5px"}}> {errors.message.message} </div> )}                                
                            </div>

                            <button className="contact-btn main-btn" type="submit" name="send">Send Message</button>
                        </form> 
                    </div>
                </div>
            </div>
        </section>
        


        <footer className="footer text-center" >
            <div className="container__two">
                <Link className="logo" to={'/'}>jsisaac</Link>
                <div className="copyright">
                    <p>All Right Reserved &copy; 2020 <Link to={'/'}>jsisaac</Link></p>
                </div>
            </div>
        </footer>


        {/* <a href="#" className="scroll-top">Top</a> */}

      </BrowserView>
      <MobileView>
            <Switch>
            <Route exact path={'/'} component={Landing} />
            <Route exact path={'/about'} component={About} />
            <Route exact path={'/skills'} component={Skills} />
            <Route exact path={'/service'} component={Services} />
            <Route exact path={'/experiance'} component={Experience} />
            {/* <Route path="*" component={PageNotFound} /> */}
            </Switch>
        <SimpleBottomNavigation />
      </MobileView>


 
        {/* <div className="preloader">
            <div className="loading-inner">
            </div>
        </div>
 */}



    </Fragment>
  );
}

export default App;
