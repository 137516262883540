import React, { Fragment } from 'react'

function Experience() {
    return (
        <Fragment>
        <section className="resume" id="resume">
			<span className="number">04</span>
            <div className="container__two">
                <div className="main-title">
                    <h3>Professional Experience &amp; </h3>
                    <h2>My Resume</h2>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="timeline">
                            <ul>
                                <li>
                                    <div className="timeline-content wow fadeInLeft" data-wow-duration=".8s" data-wow-offset="100">
                                        <span className="date">2020</span>
                                        <h4>Financial services</h4>
                                        <p>
                                            <b style={{color:"#c79864"}}>Clients:</b> Telkom kenya, Kenya ports authority, Tospay limited
                                        </p>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                                    </div>
                                </li>
                                <li>
                                    <div className="timeline-content wow fadeInRight" data-wow-duration=".8s" data-wow-offset="100">
                                        <span className="date">2020</span>
                                        <h4>Notification services</h4>
                                        <p>
                                            <b style={{color:"#c79864"}}>Clients:</b> Telkom kenya, Kenya ports authority, Tospay limited, 
                                        </p>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                                    </div>
                                </li>
                                <li>
                                    <div className="timeline-content wow fadeInLeft" data-wow-duration=".8s" data-wow-offset="100">
                                        <span className="date">2020</span>
                                        <h4>Gateway service</h4>
                                        <p>
                                            <b style={{color:"#c79864"}}>Clients:</b> Telkom kenya, Kenya ports authority, Tospay limited, Gandertechs
                                        </p>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                                    </div>
                                </li>
                                <li>
                                    <div className="timeline-content wow fadeInRight" data-wow-duration=".8s" data-wow-offset="100">
                                        <span className="date">2020</span>
                                        <h4>File service</h4>
                                        <p>
                                            <b style={{color:"#c79864"}}>Clients:</b> Personal project, Tospay limited
                                        </p>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                                    </div>
                                </li>
                                <li>
                                    <div className="timeline-content wow fadeInLeft" data-wow-duration=".8s" data-wow-offset="100">
                                        <span className="date">2020</span>
                                        <h4>Tracer service library</h4>
                                        <p>
                                            <b style={{color:"#c79864"}}>Clients:</b> Personal project
                                        </p>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>            
        </Fragment>
    )
}

export default Experience
