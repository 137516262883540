import React, { Fragment } from 'react'

function About() {
    return (
        <Fragment>
        <section className="about-me" id="about">
			<span className="number">01</span>
            <div className="container__two">
                <div className="row">
                    <div className="col-xl-5 col-lg-6">
                        <div className="about-image">
                            <img className="img-fluid" src="https://image.freepik.com/free-vector/colorful-illustration-programmer-working_23-2148281409.jpg" alt=""/>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                        <div className="about-info">
                            <div className="main-title">
                                <h3>Discover</h3>
                                <h2>About Me</h2>
                            </div>
                            <p>
                            I am...<br />
                            I am an experienced back-end software engineer currently in working at Tospay adept in bringing forth expertise in design, installation, testing, and maintenance of software systems for over 3 years, Equipped with a promising skill set. Proficient in various platforms and languages. Experienced with the latest development tools and procedures.
                            </p>
                            <p>
                            My strongest skills and how I learned them...<br />
                            I am skilled with creating back-end systems utilizing micro-services and web-sockets for real-time services. I have a background in mathematics and computer science. I later broadened my skills by using Maximilian and Mosh.
                            </p>
                            <p>
                            What I am looking for...<br />
                            I am looking for a back-end node js software engineer position. These areas often draw upon the latest technologies in mathematics and Computer Science, and I know it would not only be enjoyable to work in, but they would allow me to grow and share my skills as a software engineer and try new stack by applying acquired knowledge as an engineer.
                            </p>
                            <div className="personal-info">
								<div className="row">
									<div className="col-sm-6">
										<p><span>Name: </span>Joshua Isaac</p>
									</div>
									<div className="col-sm-6">
										<p><span>Profession: </span>Software  engineer</p>
									</div>
									<div className="col-sm-6">
										<p><span>Phone: </span>+254 770 396 785</p>
									</div>
									<div className="col-sm-6">
										<p><span>Address: </span>Nairobi, KENYA</p>
									</div>
									{/* <div className="col-sm-6">
										<p><span>Experience: </span>4 Years</p>
									</div>
									<div className="col-sm-6">
										<p><span>Freelance: </span>Available</p>
									</div> */}
								</div>
							</div>
                            {/* <a href="#" className="main-btn">Download CV</a> */}
                        </div>
                    </div>
                </div> 
            </div>
        </section>            
        </Fragment>
    )
}

export default About
